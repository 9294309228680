import React, { createContext, useEffect, useState } from "react";
import { returNombreRuta } from "../utils/funciones";
import { useLocation } from "react-router-dom";

export const DataContext = createContext();
export const ContextApi = ({ children }) => {
  const [reingresa, setReingresa] = useState(false);
  const [listaGuardado, setLista] = useState([]);
  const [visibleNotificaciones, setVisibleNotificaciones] = useState([]);
  const [nombreMenu, setNombreMenuu] = useState('');
  const [page, setPage] = useState(0);
  const [objTemporal, setObjTemporal] = useState([]);

  const [notificaciones, setNotificaciones] = useState([]);

  const location = useLocation();
  useEffect(() => { setNombreMenuu(returNombreRuta(location.pathname)); }, [location]);

  function setListaGuardado(item) { setLista([...listaGuardado, item]); }

  function setearPagina(item) { setPage(item); }

  const data = {
    reingresa, cambioReingresa: setReingresa,
    listaGuardado, setListaGuardado,
    page, setearPagina, nombreMenu,
    notificaciones, setNotificaciones,
    visibleNotificaciones, setVisibleNotificaciones,
    objTemporal, setObjTemporal
  }

  return (<DataContext.Provider value={data}>{children}</DataContext.Provider>)
}