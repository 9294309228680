
export const HtmlList = ({ lista }) => {
  return (
    <>
      <label className="h3 text-center mt-3">Guardando datos</label>
      <div>
        {
          lista.map((itm, indx) => {
            return (
              <div className="border rounded-3 py-1 px-2 d-flex justify-content-between mx-3 my-2 align-items-center" key={indx}>
                <span>{itm.texto}</span>
                <img
                  src={require(`../../../public/icon/${itm.icono}`)}
                  width="17" height="17"
                  style={{ marginTop: '3px' }}
                />
              </div>
            )
          })
        }
      </div>
    </>
  )
}