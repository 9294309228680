import moment from "moment";
import Badge from 'react-bootstrap/Badge';

export const returNombreRuta = (path) => ({
  '/': 'Solicitudes en progreso',
  '/listSolicitudAprobadas': 'Solicitudes aprobadas',
  '/listSolicitudReingreso': 'Solicitudes de reingreso',
  '/consulta-rut': 'Consulta RUT',
  '/firma-contrato': 'Gestión de firma de documentos',
  '/resumen-solicitudes': 'Resúmen de solicitudes',
  '/monitor': 'Monitor',
  '/log-temporis': 'Log de temporis',
  '/envio-sap': 'Envío a SAP',
}[path] || '');

export const devolverPlural = obj => obj.idContrato.length > 1 ? 's' : '';

export const cerrarSession = () => {
  localStorage.clear();
  // window.location = 'https://qa.miviaje.cl/home';
  window.location = 'https://qa.miviaje.cl/';
}

export const sumaStatusContrato = (arrStatus) => {
  const indx = arrStatus.findIndex((el) => el.estado_validador.nombre === "Rechazado");
  return indx !== -1
    ? "Anulado"
    : `${arrStatus.reduce((acc, val) => {
      return val.estado_validador.nombre === "Aprobado" ? acc += parseFloat(val.validador.porcentaje) : acc += 0
    }, 0)}%`
}

export const momentFecha = (txt) => moment(txt).format('DD-MM-yyyy');

export const formatDate = (dateString) => {
  const date = new Date(dateString);

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses son de 0-11
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${day}-${month}-${year} ${hours}:${minutes}`;
};

export const fechaMinimaHasta = (fecha) => (moment(fecha).add(1, 'y').format('yyyy-MM-DD')).toString()

export const evaluarEstado = (estado, perfil, idBotton) => {
  let visible = false;
  switch (idBotton) {
    case '1':
      visible = true;
      break;
    case '3':
      if ((estado === 5) && (perfil === '4')) { visible = true; }
      break;
    case '2':
      if (
        (estado === 9 || estado === 3 || estado === 8 || estado === 4 || estado === 6 || estado === 12) &&
        (perfil === '2' || perfil === '3')) {
        visible = true;
      }
      break;
    case '4':
      if (estado <= 12 || estado === 17) {
        visible = true;
      }
      break;
    case '5':
      if (
        (estado === 9 || estado === 3 || estado === 8 || estado === 4 || estado === 6 || estado === 12) &&
        (perfil !== '4')) {
        visible = true;
      }
      break;
    case '6':
      if ((estado === 7 || estado === 10) && (perfil === '4')) { visible = true; }
      break;
    case '7':
      if ((estado === 13) && (perfil === '3')) { visible = true; }
      break;
    case '50':
      if ((estado === 7 || estado === 10) && (perfil === '4')) { visible = true; }
      break;
    default:
      break;
  }
  return visible;
}

export const getBusinessDaysAgo = (daysAgo = 0) => {
  let currentDate = moment();
  const curretDateNoChange = moment();
  let daysRemaining = daysAgo;
  while (daysRemaining > 0) {
    currentDate = currentDate.subtract(1, 'days');
    daysRemaining--;
  }
  //aca voy a valiar que la fecha No sea de un mes distinto al actual
  if (currentDate._d.getMonth() !== curretDateNoChange._d.getMonth()) {
    //en caso de ser distinto devuelvo el primer dia del mes...
    return moment(curretDateNoChange).startOf('month');
  }

  return currentDate;
}

export const evaluarEstadoBadge = (estado) => {
  let badge = {};
  switch (estado) {
    case 1:
      badge = <Badge pill bg="success">Exitoso</Badge>;
      break;
    case 2:
      badge = <Badge pill bg="danger">Fallido</Badge>;
      break;
    default:
      badge = <Badge pill bg="danger">Fallido</Badge>;
      break;
  }
  return badge;
}

export const capitalizeFirstLetter = (str) => {
  return str.split(' ')
    // Transformar cada palabra para que su primera letra sea mayúscula y el resto minúscula
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    // Unir las palabras transformadas de nuevo en un string
    .join(' ');
}

export function formatearYExtraerCrearColaborador(xmlString) {
  // Parsear el XML
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(xmlString, "application/xml");

  // Buscar el elemento CrearColaborador
  const crearColaborador = xmlDoc.getElementsByTagName("CrearColaborador")[0];
  const resultado = [];

  if (crearColaborador) {
    Array.from(crearColaborador.children).forEach((elemento, index) => {
      // Envolver el nombre del nodo en un elemento <strong> para aplicar el efecto de negrita
      resultado.push(
        <span key={index}>
          <strong>{elemento.nodeName}</strong>: {elemento.textContent ? elemento.textContent : "N/A"}
        </span>
      );

      // Añadir un <br /> después de cada elemento, excepto el último
      if (index < crearColaborador.children.length - 1) {
        resultado.push(<br key={`br-${index}`} />);
      }
    });
  } else {
    resultado.push("Elemento <CrearColaborador> no encontrado.");
  }

  return resultado;
}
export const returnAnio = fecha => (new Date(fecha)).getFullYear();
