
import { BASE_URL_PRODUCCION } from "../utils/Constants";
import axios from "axios";

export const get = async (nombreApi, params, body = null) => {
  try {
    return await axios({
      method: 'get',
      url: `${BASE_URL_PRODUCCION}/listado/${nombreApi}`,
      params: params,
      body: body,
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    });
  } catch (error) { return error.request.status }
}

export const getUno = async (nombreApi, params, body = null) => {
  try {
    return await axios({
      method: 'get',
      url: `${BASE_URL_PRODUCCION}/${nombreApi}`,
      params: params,
      body: body,
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}`, 'Content-Type': 'application/json' }
    });
  } catch (error) { return error.request.status }
}

export const post = async (nombreApi, params, data = null) => {
  try {
    return await axios({
      method: 'post',
      url: `${BASE_URL_PRODUCCION}/${nombreApi}`,
      params: params,
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      data: data,
    });
  } catch (error) { return error.request.status }
}

export const put = async (nombreApi, params, data = null) => {
  try {
    return await axios({
      method: 'put',
      url: `${BASE_URL_PRODUCCION}/${nombreApi}`,
      params: params,
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      data: data,
    });
  } catch (error) { return error.request.status }
}

export const getUnooo = async (nombreApi, params, data = null) => {
  try {
    return await axios({
      method: 'get',
      url: `${BASE_URL_PRODUCCION}/listado/${nombreApi}`,
      params: params,
      data: data,
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}`, 'Content-Type': 'application/json' }
    });
  } catch (error) { return error.request.status }
}