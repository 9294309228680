import { obtenerSolicitudesReingreso } from '../api/contratacion/solicitudVacanteApi';
import { faEye, faMinusCircle, faCheckCircle, faTimesCircle, faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';
import { modalReingresoOpciones, mensajeSimple, modalReingresoAccion, modalEjecutar, modalCargando, modalHistorial } from './ui/sweetAlerts';
import { obtenerDatosPersonaSolicitanteAction } from '../redux/datosPersonaSolicitanteDucks';
import { validarRespPeticion } from '../forms/contratacion/validation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
import { usePagina } from '../hooks/hookPagina';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Spinner from './ui/Spinner';
import { getUno, post } from '../api/apis';
import Swal from "sweetalert2";
import '../css/app.css';
import { Tabs, Tab } from 'react-bootstrap';
import { momentFecha } from '../utils/funciones';
import { customStyles, objStyleCol, paginationComponentOptions } from '../utils/Constants';

export function PestaniasReingreso() {
  return (
    <Tabs
      defaultActiveKey="home"
      transition={false}
      id="noanim-tab-example"
      className="mb-0"
    >
      <Tab eventKey="home" title="Reingresos">
        <ListadoSolicitudesReingreso />
      </Tab>
      {((localStorage.getItem('id_perfil') === "4") || (localStorage.getItem('id_perfil') === "2")) &&
        <Tab eventKey="profile" title="Historial reingresos">
          <HistorialReingreso />
        </Tab>
      }
    </Tabs>
  );
}

const ButtonsRRHHyJefeZona = (props) => {
  const { vacante, aprobarReingreso, rechazarReingreso } = props;
  const { setPage } = usePagina(0);
  const idPerfil = localStorage.getItem('id_perfil');
  const txt = "Será redireccionado al formulario de contratación,<br />¿Desea continuar?";
  const navegar = useNavigate();
  const redireccion = (props) => {
    const { vacante, ruta } = props;
    localStorage.setItem('rut', vacante.identificacion);
    setPage(0);
    navegar(ruta);
  }

  return (
    <>
      {(vacante.id_solicitud_estado !== 16) &&
        <button
          className="btn btnAccion btn-danger btn-sm" data-bs-toggle="tooltip" title="Declinar solicitud"
          onClick={() => modalReingresoAccion(vacante, rechazarReingreso, 'Declinar reingreso', 2)}
        >
          <FontAwesomeIcon icon={faTimesCircle} />
        </button>
      }
      {idPerfil !== '4' &&
        <>
          <button
            className="btn btnAccion btn-success btn-sm"
            data-bs-toggle="tooltip" title="Aprobar solicitud"
            onClick={() => modalReingresoAccion(vacante, aprobarReingreso, 'Aceptar reingreso', 1)}
          >
            <FontAwesomeIcon icon={faCheckCircle} />
          </button>
          {vacante.rechazo_parcial &&
            <button
              className="btn btnAccion btn-warning btn-sm" data-bs-toggle="tooltip" title="Rechazo temporal"
              onClick={() => modalReingresoAccion(vacante, rechazarReingreso, 'Motivo de rechazo')}
            >
              <FontAwesomeIcon icon={faMinusCircle} color='white' />
            </button>
          }
        </>
      }
      {(idPerfil === '4' && (vacante.id_solicitud_estado === 5)) &&
        <button
          className="btn btnAccion btn-success btn-sm"
          data-bs-toggle="tooltip" title="Continuar proceso"
          onClick={() => modalEjecutar(redireccion, txt, { ruta: `/formulario-contratacion/${vacante.id_persona}`, vacante })}
        >
          <FontAwesomeIcon icon={faArrowAltCircleRight} />
        </button>
      }
    </>
  )
}

const ListadoSolicitudesReingreso = () => {
  const navigate = useNavigate()
  const [arrayTabla, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [pageTable, setPageTable] = useState(1);
  const dispatch = useDispatch()

  useEffect(() => { fetchUsers(pageTable) }, []);

  const aprobarReingreso = async ({ params, data }) => {
    modalCargando("Enviando reingreso...");
    const resp = await post('respreingreso', params, data);
    fetchUsers(pageTable);
    if (resp.data.status === 'success') {
      mensajeSimple('success', 'Solicitud aprobada con éxito');
    }
    // const remove = validarRespPeticion(resp);
    // if (remove) { setData(arrayTabla.filter((el) => params.idSolicitud !== el.id)); }
  }

  const rechazarReingreso = async ({ params, data }) => {
    modalCargando("Rechazando reingreso...");
    const resp = await post('respreingreso', params, data);
    fetchUsers(pageTable);
    if (resp.data.status === 'success') { mensajeSimple('success', 'Solicitud rechazada con éxito'); }
    // const remove = validarRespPeticion(resp);
    // if (remove) { setData(arrayTabla.filter((el) => params.idSolicitud !== el.id)); }
  }

  const ingresarDatosOcupante = async (rut, idSolicitud) => {
    Swal.fire({
      title: 'ingresando ....',
      didOpen: () => { Swal.showLoading() }
    })
    await dispatch(obtenerDatosPersonaSolicitanteAction(rut, idSolicitud));

    Swal.close();
    navigate("/formulario-contratacion");
  }

  const fetchUsers = async (page) => {
    setLoading(true);
    const data = await obtenerSolicitudesReingreso(page, perPage);
    setLoading(false);
    Swal.close();

    if (data.status === 'info') {
      setData([]);
      mensajeSimple(data.status, data.menssage, 2000);
    }
    if (data.status === 'success') {
      const dataTable = data.data.map((vacante, indx) => {
        let button = []
        if (vacante.ingresar_datos) {
          button = [
            <button
              className="btn borderBlanco rounded-2 btn-success btn-sm"
              data-bs-toggle="tooltip" title="Ingresar datos"
              onClick={() => ingresarDatosOcupante(vacante.identificacion, vacante.id)}
            >
              <FontAwesomeIcon icon={faCheckCircle} />
            </button>
          ]
        } else {
          button = [
            <div className='d-flex flex-row' key={indx}>
              <button
                className="btn borderBlanco roundedLeft rounded-2 btn-primary btn-sm"
                data-bs-toggle="tooltip" title="Ver solicitud"
                onClick={() => modalReingresoOpciones(vacante)}
              >
                <FontAwesomeIcon icon={faEye} title='Ver solicitud' />
              </button>
              <ButtonsRRHHyJefeZona
                vacante={vacante}
                aprobarReingreso={aprobarReingreso}
                rechazarReingreso={rechazarReingreso}
              />
            </div>
          ]
        }

        return {
          id: vacante.id,
          nombre: vacante.nombre,
          identificacion: vacante.identificacion,
          fecha_ingreso: vacante.fecha_ingreso,
          estado_actual: vacante.estado_actual,
          acciones: vacante,
          reeo_codigo_sap: vacante.reeo_codigo_sap,
          responsable: vacante.responsable,
          id_solicitud_estado: vacante.id_solicitud_estado,
          observaciones: vacante.observacion,
        }
      })

      // const set = new Set(dataTable.map(JSON.stringify))
      // const arrUnico = Array.from(set).map(JSON.parse);

      // setData(arrUnico);
      setData(dataTable);
      setTotalRows(data.paginacion.total);
      setLoading(false);
    }
  };

  const handlePageChange = page => {
    setPageTable(page)
    fetchUsers(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setPerPage(newPerPage);
    fetchUsers(page)
  };

  const columns = [
    {
      name: 'N° Personal SAP',
      selector: row => row.reeo_codigo_sap !== null ? row.reeo_codigo_sap : '---',
      style: objStyleCol,
      width: "110px",
    },
    {
      name: 'Nuevo colaborador',
      sortable: false,
      selector: row => row.nombre,
      wrap: true,
      minWidth: "250px",
    },
    {
      name: 'Rut',
      selector: row => row.identificacion,
      wrap: true,
      style: objStyleCol,
      width: "90px",
    },
    {
      name: 'Fecha Solicitud',
      selector: row => momentFecha(row.fecha_ingreso.split(' ')[0]),
      wrap: true,
      style: objStyleCol,
      width: "90px",
    },
    {
      name: 'Estado actual',
      selector: row => row.id_solicitud_estado === 16 ? `${row.estado_actual} por ${row.responsable}` : row.estado_actual,
      wrap: true,
      minWidth: "300px",
    },
    {
      name: 'Observaciones',
      selector: row => row.observaciones,
      wrap: true,
      minWidth: "200px",
    },
    {
      name: 'Acciones',
      style: objStyleCol,
      width: "120px",
      button: true,
      cell: (row) => {
        return (
          <div className='d-flex w-100'>
            <button
              className="btn btnAccion btn-primary btn-sm"
              data-bs-toggle="tooltip" title="Ver solicitud"
              onClick={() => modalReingresoOpciones(row.acciones)}
            >
              <FontAwesomeIcon icon={faEye} title='Ver solicitud' />
            </button>
            <ButtonsRRHHyJefeZona
              vacante={row.acciones}
              aprobarReingreso={aprobarReingreso}
              rechazarReingreso={rechazarReingreso}
            />
          </div>
        )
      },
    },
  ];

  const paginationComponentOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
  };

  return (
    <div className="row">
      <div className="col-sm-12 ">
        <div className="card cardPestania">
          {
            loading
              ? (<Spinner />)
              : (
                <div className="card-body contenedorTabla">
                  {arrayTabla.length > 0
                    ?
                    (
                      <DataTable
                        title={false}
                        responsive={true}
                        columns={columns}
                        data={arrayTabla}
                        progressPending={loading}
                        customStyles={customStyles}
                        pagination
                        paginationServer
                        paginationDefaultPage={pageTable}
                        paginationPerPage={perPage}
                        paginationTotalRows={totalRows}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        paginationComponentOptions={paginationComponentOptions}
                        dense
                      />
                    )
                    :
                    <p className='h5 text-center my-5 titleMensajeSimple'>No se encontraron registros</p>
                  }
                </div>
              )
          }
        </div>
      </div>
    </div>
  );
};

const HistorialReingreso = () => {
  const navigate = useNavigate()
  const [arrayTabla, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [pageTable, setPageTable] = useState(1);
  const dispatch = useDispatch()

  useEffect(() => { fetchUsers(pageTable) }, []);

  const fetchUsers = async (page) => {
    setLoading(true);
    const resp = await getUno('historialreingreso', { page: page, total_page: perPage }, {});

    setLoading(false);
    if (resp.data.status === 'success') {
      setData(resp.data.data);
      setTotalRows(resp.data.paginacion.total);
    }
  }
  const handlePageChange = page => { setPageTable(page) }
  const handlePerRowsChange = async (newPerPage) => { setPerPage(newPerPage) }

  useEffect(() => { fetchUsers(pageTable, perPage) }, [pageTable, perPage])

  const levantarHistorial = async (el) => {
    const resp = await getUno('historialreingresosolicitud', { idSolicitud: el }, null);
    if (resp?.status === 200) { modalHistorial('Historial de reingreso', resp.data.data) }
  }

  const columns = [
    {
      name: 'N° Personal SAP',
      selector: row => row.reeo_codigo_sap !== null ? row.reeo_codigo_sap : '---',
      style: objStyleCol,
      width: "110px",
    },
    {
      name: 'Nombre',
      sortable: false,
      selector: row => row.nombre,
      wrap: true,
      width: "400px",
    },
    {
      name: 'Rut',
      selector: row => row.identificacion,
      style: objStyleCol,
      width: "100px",
    },
    {
      name: 'Fecha Ingreso',
      selector: row => momentFecha(row.fecha_ingreso.split(' ')[0]),
      style: objStyleCol,
      width: "110px",
    },
    {
      name: 'Estado actual',
      sortable: false,
      selector: row => row.id_solicitud_estado === 16 ? `${row.estado_actual} por ${row.responsable}` : row.estado_actual,
      wrap: true,
      minWidth: "200px",
    },
    {
      name: 'Ver',
      cell: row =>
        <button
          className="btn btnAccion rounded-3 btn-primary btn-sm"
          data-bs-toggle="tooltip"
          title="Ver solicitud"
          onClick={() => levantarHistorial(row.id)}><FontAwesomeIcon icon={faEye} /></button>,
      wrap: true,
      width: "60px",
      style: objStyleCol
    },
  ];

  return (
    <div className="row">
      <div className="col-sm-12 ">
        <div className="card cardPestania">
          {
            loading
              ? <Spinner />
              :
              <div className="card-body contenedorTabla">
                {arrayTabla.length > 0
                  ?
                  (
                    <DataTable
                      title={false}
                      responsive={true}
                      columns={columns}
                      data={arrayTabla}
                      customStyles={customStyles}
                      progressPending={loading}
                      pagination
                      paginationServer
                      paginationDefaultPage={pageTable}
                      paginationPerPage={perPage}
                      paginationTotalRows={totalRows}
                      onChangeRowsPerPage={handlePerRowsChange}
                      onChangePage={handlePageChange}
                      paginationComponentOptions={paginationComponentOptions}
                      dense
                    />
                  )
                  :
                  <p className='h5 text-center my-5 titleMensajeSimple'>No se encontraron registros</p>
                }
              </div>

          }
        </div>
      </div>
    </div>
  );
};

export default ListadoSolicitudesReingreso;
