
import { BACKDROP, CUSTOM_CLASS} from '../../utils/Constants';
import { declinarSolicitudVacanteApirrhh } from '../../api/contratacion/solicitudVacanteApi';
import { mensajeSimple, modalCargando } from '.././ui/sweetAlerts';
import { post } from '../../api/apis';
import Swal from 'sweetalert2';



export const ModalRechazoSolicitudrrhh = async (idVacante,
     data_posicion,
      navigate
      , navigateroute="/resumen-solicitudes") => {
    const { value: text } = await Swal.fire({
        input: 'textarea',
        title: 'Motivo para declinar',
        inputPlaceholder: 'Escribe tu motivo...',
        inputAttributes: { 'aria-label': 'Type your message here' },
        reverseButtons: false,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Declinar',
        customClass: CUSTOM_CLASS,
        backdrop: BACKDROP,
        showCancelButton: true,
        allowOutsideClick: false,
        buttonsStyling: false
      })
  
      if (text) {
        Swal.fire({
          title: '¿Estas seguro de declinar la vacante?',
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'Declinar',
          cancelButtonText: 'Cancelar',
          customClass: CUSTOM_CLASS,
          showLoaderOnConfirm: true,
          backdrop: BACKDROP,
          buttonsStyling: false,
          reverseButtons: false,
          preConfirm: () => {
            return declinarSolicitudVacanteApirrhh(idVacante, text).then(async (response) => {
              if (response.status === 'error') { throw new Error('Ocurrio un error al momento de realizar esta acción.') }
              return response
            }).catch(() => mensajeSimple('error', 'Ocurrio un error al momento de realizar esta acción', 4000))
          },
          allowOutsideClick: () => !Swal.isLoading()
        }).then(async (result) => {
            if (result.isDenied) {
                Swal.fire('No se declinó la vacante', '', 'info')
                return
            }
            if (data_posicion === "" || data_posicion === null){
                mensajeSimple('success', "solicitud declinada", 4000)
                navigate(navigateroute)
                return
            }

            if (result.value.status === 'success') {
              modalCargando("Liberando posición...");
              //se deja la fecha de inicio la fecha de inicio de AÑO, consultado con lenin
              const resp = await post('desbloqueoposicion', { codPosicion: data_posicion, fechaInicio: `${new Date().getFullYear()}0101`});
              if (resp.data.status === 'info') { 
                mensajeSimple('info', 'La posición no pudo ser liberada en sap por '+resp.data.message, 4000);
                navigate(navigateroute)
               }
              if (resp.data.status === 'success') {
                mensajeSimple(resp.data.status, resp.data.message, 4000);
                navigate(navigateroute)

              }
            }
            if (result.value.status === 'info') { mensajeSimple('error', 'No se pudo declinar.', 2500); }
          
        })
      }
}
