import { BASE_URL_PRODUCCION } from "../../utils/Constants"

export const obtenerDatosTemporisApi = async (Localcodigoerp,planHorario = "45") => {
    try {
        const params = {
            headers:{
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            }
        } 
        const url = `${BASE_URL_PRODUCCION}/temporis?Localcodigoerp=${Localcodigoerp}&plan_horario=${planHorario}`
        const res = await fetch(url,params)
        const resJSON = await res.json()

        return resJSON

    } catch (error) {
        console.log("Error :", error)
    }
}

export const SubirDataTemporisApi = async (id_persona) => {
    try {
        const url = `${BASE_URL_PRODUCCION}/temporis`
        const params = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
          method: "POST",
          body: JSON.stringify({
            id_persona: id_persona
          })
        }
        const res = await fetch(url, params);
        const resJSON = await res.json()
        return resJSON;
    
      } catch (error) {
        console.log("Error: ", error)
        return ""
      }
}