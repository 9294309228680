import Select from 'react-select';

export const InputSelect = (props) => {
  const { arr = [], id, formik, label = 'XXXXXXXXX', mayuscula = false } = props;

  const arrOpts = t => t.map((el) => ({ label: el.nombre, value: el.id }));

  return (
    <>
      <label htmlFor={id}>{label}</label>
      <Select
        name={id}
        inputId={id}
        className="selectDos"
        onChange={(e) => {
          formik.setValues({ ...formik.values, [id]: e ? e.value : '' });
        }}
        placeholder='---Seleccione---'
        defaultValue=''
        isClearable={true}
        classNamePrefix={'limpiarSelect'}
        options={arrOpts(arr)}
      />
    </>
  )
}


document.querySelector("#formSearchAdmin > div > div:nth-child(1) > div:nth-child(1) > div > div > div.limpiarSelect__indicators.css-1hb7zxy-IndicatorsContainer > div.limpiarSelect__indicator.limpiarSelect__clear-indicator.css-1xc3v61-indicatorContainer")