import '../../css/sidebar.css';
import { Link } from 'react-router-dom';
import { Icono } from './Iconos';
import { useContext, useState } from 'react';
import { DataContext } from '../../contextData/contextData';
import { useEffect } from 'react';
import { getUno } from '../../api/apis';

const returnTo = () => ['7'].includes(localStorage.getItem('id_perfil')) ? '/' : '/firma-contrato';

export const SideBar = () => {
  const idPerfil = localStorage.getItem('id_perfil');
  const { notificaciones } = useContext(DataContext);
  const [version, setVersion] = useState(undefined);

  useEffect(() => {
    async function obtenerVersion() {
      const resp = await getUno('version');
      setVersion(resp.data);
    }
    obtenerVersion();
  }, []);

  return (
    <>
      <div className="offcanvas modalSideBar offcanvas-start" tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
        <div className="offcanvas-header bgArcoprime">
          <a className="navbar-brand" href="/">
            <div className='logoBlanco'></div>
          </a>
          <button className="btn btn-primary btnSidebar" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample" style={{ paddingRight: "12px", paddingLeft: "10px" }}>
            <Icono iconName='arrowLeft' />
          </button>
        </div>
        <div className="offcanvas-body">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            {['6'].includes(idPerfil) &&
              <li className="nav-item rounded-3 ps-2 linkNavBar">
                <Icono iconName='pantalla' />
                <Link to="/" className="nav-link active py-3 w-100">Monitor</Link>
              </li>
            }
            {['4'].includes(idPerfil) &&
              <li className="nav-item rounded-3 ps-2 linkNavBar">
                <Icono iconName='consulta' />
                <Link to="/consulta-rut" className="nav-link active py-3 w-100">Iniciar Contratación</Link>
              </li>
            }
            {!(["6", "7"].includes(idPerfil)) &&
              <>
                <li className="nav-item rounded-3 ps-2 linkNavBar">
                  <Icono iconName='prog' />
                  <Link to="/" className="nav-link active py-3 w-100">Solicitudes en progreso</Link>
                </li>

                <li className="nav-item rounded-3 ps-2 linkNavBar">
                  <Icono iconName='reing' />
                  <Link to="/listSolicitudReingreso" className="nav-link active py-3 w-100">Solicitudes de reingreso</Link>
                </li>
              </>
            }
            {(['7'].includes(idPerfil) || ['1'].includes(localStorage.getItem('puedeverfirma'))) &&
              //a esta seccion solo pueden ver perfil 7 O si tiene el token de tiene documentos o... es usuario monitor
              <li className="nav-item rounded-3 ps-2 linkNavBar">
                <Icono iconName='firma' />
                <Link to={returnTo()} className="nav-link active py-3 w-100">Gestión de Firma de Documentos</Link>
              </li>
            }
            {['3'].includes(idPerfil) &&
              <>
                <li className="nav-item rounded-3 ps-2 linkNavBar">
                  <Icono iconName='enviosap' />
                  <Link to="/envio-sap" className="nav-link active py-3 w-100">Por enviar a SAP</Link>
                </li>
                <li className="nav-item rounded-3 ps-2 linkNavBar">
                  <Icono iconName='resumen' />
                  <Link to="/resumen-solicitudes" className="nav-link active py-3 w-100">Resúmen de solicitudes</Link>
                </li>
                {/* Log de temporis */}
                <li className="nav-item rounded-3 ps-2 linkNavBar">
                  <Icono iconName='temporislog' />
                  <Link to="/log-temporis" className="nav-link active py-3 w-100"> Log de Temporis</Link>
                </li>
                {/* Fin log de temporis */}
              </>
            }
          </ul>
        </div>
        <div className='px-3 pb-3'>
          <p className='h6 mb-1 d-flex justify-content-end linkNavBar'>Versión: {version}</p>

          <ul id='itemBar2' className='navbar-nav mb-2 mb-lg-0 d-flex justify-content-between flex-row bgArcopime'>
            <li className="d-flex flex-column justify-content-center px-2" style={{ whiteSpace: 'nowrap', fontSize: '14px' }}>
              <label className="text-white"> {localStorage.getItem('usuarioNombre')} </label>
              <label className="text-white fw-bold"> {localStorage.getItem('perfil')} </label>
            </li>

            <li className="nav-item">
              <div className="dropdown text-end">
                <div className="p-2 d-block link-dark text-decoration-none dropdown-toggle btnCerrarSesion rounded-3" id="dropdownUser1" data-bs-toggle="dropdown">
                  <img src={require('../../assets/untitled.png')} alt="mdo" width="40" height="40" className="rounded-circle" />
                  {(notificaciones.length > 0) &&
                    <span
                      className='badge bg-warning text-dark p-1 rounded-circle d-flex justify-content-center'
                      style={{
                        position: 'absolute',
                        fontSize: '11px',
                        top: '30px',
                        right: '62px',
                        height: '20px',
                        width: '20px',
                        fontWeight: '500',
                        cursor: 'pointer'
                      }}
                    >
                      <Icono iconName='notif' />
                    </span>
                  }
                </div>
                <ul className="dropdown-menu dropdown-menu-end text-small" aria-labelledby="dropdownUser1" >
                  {(notificaciones.length > 0) &&
                    <li key={0} className="dropdown-item fw-bold d-flex justify-content-between align-items-center px-2" style={{ cursor: 'pointer' }}>
                      Notificaciones
                      <span
                        className="badge rounded-pill bg-warning text-dark d-flex justify-content-center align-items-center"
                        style={{ height: '20px', width: '20px', paddingBottom: '6px' }}
                      >{notificaciones.length}</span>
                    </li>
                  }
                  <li key={1}><a className="dropdown-item px-2" href="https://qa.miviaje.cl/home">
                    Cerrar Sesión
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-right iconoSession" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z" />
                      <path fillRule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z" />
                    </svg>
                  </a></li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}