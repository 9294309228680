import jwtDecode from "jwt-decode"
import { BASE_URL_PRODUCCION } from "../../utils/Constants"

export const guardarDatosLaboralesApi = async (data, idSolicitud,endpoint="vacante") => {
  try {
    const url = `${BASE_URL_PRODUCCION}/${endpoint}`
    const { cargo, planHorario, unidadOrganizativaRolTemporis, tipoContrato, fechaFinContrato, fechaIngreso } = data
    const cargoFormateado = JSON.parse(cargo)
    const planHorarioFormateado = planHorario

    const params = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      method: "POST",
      body: JSON.stringify({
        data_posicion: cargoFormateado,
        tipo_contrato: tipoContrato,
        fecha_ingreso: fechaIngreso,
        fecha_retiro: fechaFinContrato,
        temporis_plan_horario: {
          data: planHorarioFormateado
        },
        temporis_unidad_rol: {
          data: unidadOrganizativaRolTemporis
        },
        id_solicitud: idSolicitud
      })
    }
    const res = await fetch(url, params);
    const resJSON = await res.json()
    return resJSON;

  } catch (error) {
    console.log("Error: ", error)
    return ""
  }
}

export const obtenerPosicionesLaboralesApi = async () => {
  const tokenData = jwtDecode(localStorage.getItem('token'));
  try {
    const params = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }
    }
    const url = `${BASE_URL_PRODUCCION}/posiciones?idUsuarioSap=${tokenData.id_usuario_sap}`
    const res = await fetch(url, params)
    const resJSON = await res.json()
    return resJSON
  } catch (error) {
    console.log("Error :", error)
  }
}

export const obtenerDatosLaboralesApi = async () => {
  try {
    const params = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }
    }
    const url = `${BASE_URL_PRODUCCION}/posiciones`
    const res = await fetch(url, params)
    const resJSON = await res.json()
    return resJSON
  } catch (error) {
    console.log("Error :", error)
  }
}