import React, { useState, useEffect, useContext } from "react";
import { GuardarDatosPersonaSoloRut,actualizarDatosPersonalesApi } from "../api/contratacion/datosPersonalesApi";
import { guardarDatosPrevisionApi } from "../api/contratacion/datosPrevisionApi";
import { guardarDatosBancariosApi } from "../api/contratacion/datosBancariosApi";
import { guardarDatosLaboralesApi } from "../api/contratacion/datosLaboralesApi";
import { guardarDireccionApi } from "../api/contratacion/datosDireccionApi";
import FormDatosPersonales from "./procesoContratacion/FormDatosPersonales";
import FormDatosPrevision from "./procesoContratacion/FormDatosPrevision";
import FormDatosBancarios from "./procesoContratacion/FormDatosBancarios";
import FormDatosDireccion from "./procesoContratacion/FormDatosDireccion";
import FormDatosLaborales from "./procesoContratacion/FormDatosLaborales";
import { guardarDatosSaludApi } from "../api/contratacion/datosSaludApi";
import { guardarDocumentoApi } from "../api/contratacion/documentosApi";
import { SubirDataTemporisApi } from "../api/listas/temporisApi.js";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import FormDocumentos from "./procesoContratacion/FormDocumentos";
import { txtMensajeDatosIngresados } from "../utils/Constants.js";
import { DataContext } from "../contextData/contextData";
import { BACKDROP } from '../utils/Constants.js';
import { DOCUMENTOS } from "../utils/Constants";
import { SpinnerModal } from "./ui/Spinner";
import { getUno, post } from "../api/apis";
import { useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import {
  aprobarSolicitudVacanteApi,
  guardadoSolicitudInicialApi,
  NotificacionCorreoPyr,
  UpdateSolicitudNoTemporal
} from "../api/contratacion/solicitudVacanteApi";
import {
  modalDeclinarSolicitud,
  modalDeclinarProceso,
  mensajeSimple,
  modalCargando,
  modalEjecutar,
} from "./ui/sweetAlerts";
import { guardarDatosPersonaExtranjeraApi } from "../api/contratacion/datosPersonaExtranjeraApi";

import { useDatosPersonalesExtranjero } from "./procesoContratacion/useDatosPersonalesExtranjero.js";
import { useDatosPersonales } from "./procesoContratacion/useDatosPersonales.js";
import { useDatosDireccion } from "./procesoContratacion/useDatosDireccion.js";
import { useDatosLaborales } from "./procesoContratacion/useDatosLaborales.js";
import { useDatosBancarios } from "./procesoContratacion/useDatosBancarios.js";
import { useDatosPrevision } from "./procesoContratacion/useDatosPrevision.js";
import { useDocumentos } from "./procesoContratacion/useDocumentos.js";

const HtmlList = ({ lista }) => {
  return (
    <>
      <label className="h3 text-center mt-3">Guardando datos</label>
      <div>
        {
          lista.map((itm, indx) => {
            return (
              <div className="border rounded-3 py-1 px-2 d-flex justify-content-between mx-3 my-2 align-items-center" key={indx}>
                <span>{itm.texto}</span>
                <img
                  src={require(`../../public/icon/${itm.icono}`)}
                  width="17"
                  height="17"
                  style={{ marginTop: '3px' }}
                />
              </div>
            )
          })
        }
      </div>
    </>
  )
}

const FormContratacionScreen = () => {
  const { page, setearPagina } = useContext(DataContext);
  const [listaGuardado, setListaGuardado] = useState([]);
  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleSpinner, setVisibleSpinner] = useState(true);
  const [txtSpinner, setTxtSpinner] = useState('');
  const { idPersona } = useParams();
  const [bloqueado, setBloqueado] = useState(false);
  const navigate = useNavigate();
  const msjCorrecto = ", guardado correctamente."
  const msjIncorrecto = ", no se pudo guardar.";
  const FormTitles = [
    "Datos Laborales",
    "Datos Personales",
    "Datos de dirección",
    "Datos de previsión",
    "Datos bancarios",
    "Documentos / Soporte de contratacion",
  ];

  const { validatePersonales } = useDatosPersonales();
  const { validatePersonalesExtranjero } = useDatosPersonalesExtranjero();
  const { validateDireccion } = useDatosDireccion();
  const { validateLaborales } = useDatosLaborales();
  const { validatePrevision } = useDatosPrevision();
  const { validateBancarios } = useDatosBancarios();
  const { validateDocumentos } = useDocumentos();

  function setearMensaje(tipo, resp) {
    setListaGuardado(
      (listaGuardado) => [
        ...listaGuardado,
        {
          icono: `${resp.status === 'success' ? 'check.png' : 'warning.png'}`,
          texto: `${tipo}${resp.status === 'success' ? msjCorrecto : msjIncorrecto}`,
          Tooltip: `${resp.status === 'info' ? resp.message : ''}`
        }
      ]
    );
  }

  const returnTelefono = telf => telf.length > 8 ? telf.substr(1, 8) : telf;

  async function getUnaPersona(id) {
    const respPersona = await getUno('persona', { idPersona: id });

    const {
      apellido_mat,
      apellido_pat,
      correo,
      fecha_nacimiento,
      // id,
      id_educacion_nivel,
      id_persona_estado_civil,
      id_pais,
      id_persona_genero,
      identificacion,
      nombre,
      telefono
    } = respPersona.data.data;

    formik.setValues({
      ...formik.values, datosPersonales:
      {
        nacionalidad: id_pais,
        apellidoMaterno: apellido_mat,
        apellidoPaterno: apellido_pat,
        email: correo,
        estadoCivil: id_persona_estado_civil,
        sexo: id_persona_genero,
        nombres: nombre,
        nivelFormacion: id_educacion_nivel || '',
        rut: identificacion,
        fechaNacimiento: fecha_nacimiento !== 'None' ? fecha_nacimiento : '',
        telefonoCelular: returnTelefono(telefono.replace(/\s/g, '')),
      }
    });
  }

  const validaRut = () => {
    if (!localStorage.getItem('rut')) { navigate('/consulta-rut'); }
  }

  useEffect(() => {
    if (idPersona) {
      getUnaPersona(idPersona);
    }
    validaRut();
  }, []);


  useEffect(() => {
    validaRut();
  }, [page]);

  const saveNewDataFrom2 = async (values, personaSolicitante = false, editaVacante = true) => {
    if (!localStorage.getItem('rut')) {
      mensajeSimple('¡No existe un rut asociado a este colaborador!');
      return;
    }
    const valuesPersonales = { ...values.datosPersonales, rut: localStorage.getItem('rut')};
    setVisibleModal(!visibleModal);

    setTxtSpinner(() => setTxtSpinner('Datos personales'));
    let resp = await actualizarDatosPersonalesApi(localStorage.getItem('id_persona_base') ,valuesPersonales);
    setearMensaje('Datos personales', resp);


    //aqui esta el problema
    const idPersona = resp?.data?.id_persona;

    if (!["1",""].includes(values.datosPersonales.nacionalidad)) {
      setTxtSpinner(() => setTxtSpinner('Datos persona extranjera'));
      const respExtranjero = await guardarDatosPersonaExtranjeraApi(values.datosPersonaExtranjera, idPersona);
      setearMensaje('Datos persona extranjera', respExtranjero);
    }
    if (page >= 2) {
      setTxtSpinner(() => setTxtSpinner('Datos dirección'));
      resp = await guardarDireccionApi(values.datosDireccion, idPersona);
      setearMensaje('Datos dirección', resp);
    }
    if (page >= 3) {
      setTxtSpinner(() => setTxtSpinner('Datos previsión'));
      resp = await guardarDatosPrevisionApi(values.datosPrevision, idPersona);
      setearMensaje('Datos previsión', resp);

      setTxtSpinner(() => setTxtSpinner('Datos salud'));
      resp = await guardarDatosSaludApi(values.datosPrevision, idPersona);
      setearMensaje('Datos salud', resp);
    }
    if (page >= 4) {
      setTxtSpinner(() => setTxtSpinner('Datos bancarios'));
      resp = await guardarDatosBancariosApi(values.datosBancarios, idPersona);
      setearMensaje('Datos bancarios', resp);
    }

    if (page === 5) {
      const arr_resp = await Promise.all(DOCUMENTOS.map(async (dataDocumento) => {
        if (values.datosDocumentos[dataDocumento.documento] !== null) {
          setTxtSpinner(() => setTxtSpinner('Documento'));
          const respDocumento = await guardarDocumentoApi(values.datosDocumentos[dataDocumento.documento], idPersona, dataDocumento['id_tipo'])
          setearMensaje('Documento', respDocumento);
        }
      }));
      console.log(arr_resp);
    }

    if (values.guardado_temporal === 1) {
      // Llamar api /api/logguardadotemporal con los datos de la solicitud
      await post('logguardadotemporal', { idSolicitud: localStorage.getItem('id_solicitud_base')});
    }

    if (personaSolicitante && editaVacante) {
      let ingresarDatosLAborales = false;

      if (!personaSolicitante.idSolicitud) {
        setTxtSpinner(() => setTxtSpinner('Solicitud'));

        if ((page < FormTitles.length - 1) && (page > 0)) {
          var respVacante = {"status":"success","message":"vacante base creada"} // se deja en duro YA QUE la solicitud partcial ya existe...
        } else {
          var respVacante = await UpdateSolicitudNoTemporal(localStorage.getItem('id_solicitud_base'));
          
        }
        if (respVacante.status === 'success') { ingresarDatosLAborales = true; }
        setearMensaje('Solicitud', respVacante);
      }

      if (ingresarDatosLAborales || personaSolicitante.idSolicitud) {
        const id_solicitud = personaSolicitante.idSolicitud ? personaSolicitante.idSolicitud : localStorage.getItem('id_solicitud_base');
        setTxtSpinner(() => setTxtSpinner('Datos laborales'));
        const respDatosLaborales = await guardarDatosLaboralesApi(values.datosLaborales, id_solicitud);
        setearMensaje('Datos laborales', respDatosLaborales);

        if (localStorage.getItem('id_solicitud_base') && page === 5) {
          setTxtSpinner(() => setTxtSpinner('Vacante'));
          let  respuestatemporis = await SubirDataTemporisApi(idPersona);
          const respSolicitante = await aprobarSolicitudVacanteApi(id_solicitud);

          setearMensaje('Vacante', respSolicitante);
        }

        if (respDatosLaborales.status === "success") {
          setTimeout(() => {
            localStorage.removeItem('rut');
            window.location = "/";
          }, 4000);
        }
      }
    }
    setVisibleSpinner(!visibleSpinner);
  }

  const PageDisplay = () => {
    if (page === 0) { return <FormDatosLaborales bloqueado={bloqueado} formik={formik} />; }
    if (page === 1) { return <FormDatosPersonales formik={formik} />; }
    if (page === 2) { return <FormDatosDireccion formik={formik} />; }
    if (page === 3) { return <FormDatosPrevision formik={formik} />; }
    if (page === 4) { return <FormDatosBancarios formik={formik} />; }
    if (page === 5) { return <FormDocumentos formik={formik} />; }
  }

  const validate = (values) => {
    const { nacionalidad } = values.datosPersonales;
    const { tipoVisa } = values.datosPersonaExtranjera;
    const { jubilado } = values.datosPrevision;
    const { certificadoCovid } = values.datosPrevision;

    let errors = {
      datosLaborales: validateLaborales(values.datosLaborales),
      datosPersonales: validatePersonales(values.datosPersonales),
      datosPersonaExtranjera: validatePersonalesExtranjero({ ...values.datosPersonaExtranjera, nacionalidad }),
      datosDireccion: validateDireccion(values.datosDireccion),
      datosPrevision: validatePrevision(values.datosPrevision),
      datosBancarios: validateBancarios(values.datosBancarios),
      datosDocumentos: validateDocumentos({ ...values.datosDocumentos, certificadoCovid, tipoVisa, jubilado, nacionalidad }),
    };

    const noHayErrores = Object.values(errors).every(el => Object.values(el).length === 0);
    return noHayErrores ? null : errors;
  };
  const personaSolicitante = useSelector(store => store.datosPersonaSolicitante.dataPersona);
  const guardarProcesoDeContratacion = (values) => {
    saveNewDataFrom2(values, personaSolicitante);
  }

  const formik = useFormik({
    initialValues: {
      datosLaborales: {
        fechaIngreso: "",
        cargo: "",
        planHorario: "",
        unidadOrganizativaRolTemporis: "",
        cargoRolTemporis: "",
        tipoContrato: "",
        fechaFinContrato: "",
      },
      datosPersonales: {
        rut: personaSolicitante.rut,
        id_persona: personaSolicitante.data.id_persona ? personaSolicitante.data.id_persona : "",
        nombres: "",
        apellidoPaterno: "",
        apellidoMaterno: "",
        fechaNacimiento: "",
        sexo: "",
        estadoCivil: "",
        nivelFormacion: "",
        nacionalidad: "",
        email: "",
        telefonoCelular: "",
      },
      datosPersonaExtranjera: {
        tipoVisa: 0,
        visaTemporariaDesde: "",
        visaTemporariaHasta: "",
      },
      datosDireccion: {
        calle: "",
        numeroCasa: "",
        numeroDepartamento: "",
        region: "",
        comuna: "",
        tipoDireccion: ''
      },
      datosPrevision: {
        jubilado: "",
        previsionSalud: "",
        previsionSocial: "",
        planPactado: "",
        certificadoCovid: "",
        certificadoDiscapacidad: ""
      },
      datosBancarios: {
        formaDePago: "",
        banco: "",
        tipoCuenta: "",
        numeroCuenta: "",
      },

      datosDocumentos: {
        curriculum: null,
        cedula: null,
        antecedentes: null,
        residencia: null,
        afp: null,
        salud: null,
        visa: null,
        discapacidad: null,
        covid: null,
        jubilacion: null,
      },
      guardado_temporal: 0, // Este campo se agrega para generar el log respectivo al guardado temporal de la solicitud. Es así para no modificar más el flujo de la contratación.
    },
    enableReinitialize: true,
    validate,
    onSubmit: (values) => { guardarProcesoDeContratacion(values) },
  });

  const verHabilitado = (page, errors) => {
    let deshabilitado = true;
    const { datosLaborales, datosPersonales, datosPersonaExtranjera, datosDireccion, datosPrevision, datosBancarios, datosDocumentos } = errors;

    const habilitar = obj => (!obj || Object.keys(obj).length === 0) ? false : true;

    switch (page) {
      case 0:
        deshabilitado = habilitar(datosLaborales);
        break;
      case 1:
        deshabilitado = habilitar({ ...datosPersonales, ...datosPersonaExtranjera });
        break;
      case 2:
        deshabilitado = habilitar(datosDireccion);
        break;
      case 3:
        deshabilitado = habilitar(datosPrevision);
        break;
      case 4:
        deshabilitado = habilitar(datosBancarios);
        break;
      case 5:
        deshabilitado = habilitar(datosDocumentos);
        break;
      default:
        deshabilitado = true;
        break;
    }
    return deshabilitado;
  }

  const disabledButtonNext = () => formik.dirty ? verHabilitado(page, formik.errors) : true;

  const avanzar = async () => {
    //#region 
    // localStorage.setItem('page', parseInt(page) + 1);
    // setearPagina((currentPage) => currentPage + 1);
    // setBloqueado(true);
    // return;
    //#endregion

   
    modalCargando('Bloqueando posición...');
    const { fechaIngreso, cargo } = formik.values.datosLaborales;
    const resp = await post('bloqueoposicion', { codPosicion: JSON.parse(cargo).MOES_COD_POSICION, fechaInicio: fechaIngreso });

    if (resp.data.status === 'info') { mensajeSimple('info', resp.data.message, 4500); }
    else if (resp.data.status === 'success') {

    //creo persona solo con rut... 
    let datapersona  = await GuardarDatosPersonaSoloRut(localStorage.getItem('rut'))
    var respVacante = await guardadoSolicitudInicialApi( datapersona.data, 1, 1);

    localStorage.setItem('id_solicitud_base',respVacante.data.id_solicitud)

      mensajeSimple(resp.data.status, resp.data.message, 5000);
      setTimeout(() => {
        localStorage.setItem('page', parseInt(page) + 1);
        setearPagina((currentPage) => currentPage + 1);
        setBloqueado(true);
      }, 5000);
    }
    else { mensajeSimple('error', 'Ha ocurrido un error.'); }
  }

  const resetValores = (arr) => {
    arr.forEach((el) => formik.setFieldValue(`datosDocumentos.${el}`, null));
  }

  const declinar = () => {
    localStorage.setItem('page', parseInt(page) - 1);
    setearPagina((currentPage) => currentPage - 1);
    resetValores([
      'curriculum',
      'cedula',
      'antecedentes',
      'residencia',
      'afp',
      'salud',
      'visa',
      'discapacidad',
      'covid',
      'jubilacion'
    ]);
  }

  const funcionMostrarModal = () => {
    let mostrar = false;
    (Object.values(formik.values.datosDocumentos)).forEach((el) => {
      if (el !== null) { mostrar = true; }
    });
    return mostrar;
  }

  const evaluarRetorno = () => {
    if (funcionMostrarModal()) {
      modalDeclinarProceso(declinar)
    }
    else {
      localStorage.setItem('page', parseInt(page) - 1);
      setearPagina((currentPage) => currentPage - 1);
    }
  }

  const declinarSolicitud = async (codPosicion) => {
    const { fechaIngreso, cargo } = formik.values.datosLaborales;
    const resp = await post('desbloqueoposicion', { codPosicion: JSON.parse(cargo).MOES_COD_POSICION, fechaInicio: fechaIngreso });

    if (resp.data.status === 'info') {
      mensajeSimple('error', 'La posición no pudo ser liberada.', 2500);
    }
    else if (resp.data.status === 'success') {
      mensajeSimple(resp.data.status, resp.data.message, 5000);
      setTimeout(() => {
        localStorage.setItem('page', 0);
        setearPagina(0);
        if (page !== 1) { navigate('/consulta-rut'); }
      }, 5000);
    }
    else { mensajeSimple('error', 'Ha ocurrido un error.'); }
  }

  return (
    <>
      <Modal show={visibleModal}>
        <HtmlList lista={listaGuardado} />

        {visibleSpinner && <SpinnerModal txt={txtSpinner} />}

        {!visibleSpinner &&
          <>
            <div className="text-center">
              <button
                className="btnArcoprime mb-4 mt-2"
                onClick={() => {
                  setVisibleSpinner(!visibleSpinner);
                  setVisibleModal(!visibleModal);
                  window.location = "/";
                }}
              >Entendido</button>
            </div>
          </>
        }
      </Modal>
      <div className="form mx-5">
        <div className="form-container mx-5">
          <div className="header">
            <div className="progress my-3">
              <div
                className="progress-bar progress-bar-striped progress-bar-animated"
                role="progressbar"
                aria-valuenow="75"
                aria-valuemin="0"
                aria-valuemax="100"
                style={{
                  width:
                    page === 0
                      ? "0%"
                      : page === 1
                        ? "20%"
                        : page === 2
                          ? "40%"
                          : page === 3
                            ? "60%"
                            : page === 4
                              ? "80%"
                              : "100%",
                }}
              ></div>
            </div>
            <div className=" d-flex justify-content-between align-items-baseline">
              <div>
                <h1 className="mb-0"> {FormTitles[page]}{" "} </h1>
                <span className="mt-0" style={{ weight: '700' }}>
                  Campos requeridos poseen este símbolo (*)
                </span>
              </div>

            </div>
          </div>

          <div className="body">{PageDisplay()} </div>
          <div className="footer mb-5">
            <div className="float-end">
              {((page < FormTitles.length - 1) && (page > 0)) &&
                <button className="btn btn-success mt-4 me-2" type="button"
                  onClick={() => { 
                    formik.values.guardado_temporal = 1; // Se setea guardado temporal en 1 para generar el log respectivo
                    guardarProcesoDeContratacion(formik.values) }}
                >Guardado temporal</button>
              }
              {(bloqueado && page !== 0) &&
                <button
                  className="btn btn-danger align-items-center mt-4 me-5 candadoPosicion"
                  onClick={() => {
                    const { cargo } = formik.values.datosLaborales;
                    modalDeclinarSolicitud(
                      declinarSolicitud,
                      JSON.parse(cargo).MOES_COD_POSICION,
                      `¿Está seguro de liberar la posición? Se perderá el proceso actual y la posición <b>${JSON.parse(cargo).MOES_COD_POSICION} - ${JSON.parse(cargo).MOES_DESCRIPCION_POSICION}</b> estará disponible nuevamente.`);
                  }}
                >
                  Declinar solicitud
                </button>
              }
              <button
                className="btn btn-outline-primary mt-4 me-2"
                onClick={() => {
                  if (page === 0) { navigate('/consulta-rut') }
                  else if (page === 1 && bloqueado) {
                    const { cargo } = formik.values.datosLaborales;
                    modalDeclinarSolicitud(
                      declinarSolicitud,
                      JSON.parse(cargo).MOES_COD_POSICION,
                      txtMensajeDatosIngresados
                    );
                  }
                  else if (page === 5) { evaluarRetorno(); }
                  else {
                    localStorage.setItem('page', parseInt(page) - 1);
                    setearPagina((currentPage) => currentPage - 1);
                  }
                }}
              >Ir atrás</button>
              <button
                id="btnContinuarContratacion"
                disabled={disabledButtonNext()}
                className={page === FormTitles.length - 1 ? `btn btn-success mt-4 ` : `btn btn-primary mt-4 `}
                onClick={() => {
                  if (page === FormTitles.length - 1) {
                    Swal.fire({
                      title: "¿Deseas finalizar el proceso?",
                      showCancelButton: true,
                      cancelButtonText: "Cancelar",
                      confirmButtonText: "Finalizar",
                      reverseButtons: false, // orden botones al reves
                      customClass: {
                        container: 'containerMensajeSimple py-3',
                        text: 'txtLabellInput',
                        popup: `popUpSweetCancelarEnviar`,
                        confirmButton: 'btnCopec me-4',
                        cancelButton: 'btnAtras',
                      },
                      buttonsStyling: false,
                      backdrop: BACKDROP,
                    }).then((result) => {
                      if (result.isConfirmed) { formik.handleSubmit(); }
                      else { Swal.close(); }
                    });
                  } else {
                    if (page === 0) {
                      const txt = `¿Está seguro que el cargo y el plan de horario seleccionados son correctos?
                                    Si procede con el proceso de contratación los campos anteriores no serán editables`;
                      modalEjecutar(avanzar, txt, null);
                    }
                    else {
                      localStorage.setItem('page', parseInt(page) + 1);
                      setearPagina((currentPage) => currentPage + 1);
                    }
                  }
                }
                }
              >
                {page === FormTitles.length - 1 ? "Enviar a Aprobación" : "Continuar"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormContratacionScreen;