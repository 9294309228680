import React, { useEffect } from "react";
import { usePrevision } from "../../hooks/datosPrevisionales";
import NumberFormat from "react-number-format";
import ErrorInput from "../ui/ErrorInput";

const FormDatosPrevision = ({ formik }) => {
  const { getListaAfp, getListaSalud, listadoAfp, listadoPrevision } = usePrevision();

  const getInfo = () => {
    getListaAfp('afp', { jubilado: 0 });
    getListaSalud('prevision', null);
  }

  useEffect(() => { getInfo() }, []);

  useEffect(() => {
    if (formik.values.datosPrevision.previsionSalud === '1') {
      formik.values.datosPrevision.planPactado = 0;
      formik.setValues(formik.values);
    }
  }, [formik.values.datosPrevision.previsionSalud]);

  useEffect(() => {
    getListaAfp('afp', { jubilado: formik.values.datosPrevision.jubilado });
  }, [formik.values.datosPrevision.jubilado]);

  return (
    <>
      <div className="row mt-3">
        <div className="col-12 col-sm-6 my-1">
          <label htmlFor="certificadoCovid">Tiene certificado covid</label>
          <select
            className="form-select"
            name="datosPrevision.certificadoCovid"
            aria-label="Default select example"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.datosPrevision.certificadoCovid}
          >
            <option value="">Seleccione</option>
            <option value={0}>No</option>
            <option value={1}>Si</option>
          </select>
        </div>
        <div className="col-12 col-sm-6 my-1">
          <label htmlFor="certificadoDiscapacidad">Tiene certificado discapacidad</label>
          <select
            className="form-select"
            name="datosPrevision.certificadoDiscapacidad"
            aria-label="Default select example"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.datosPrevision.certificadoDiscapacidad}
          >
            <option value="">Seleccione</option>
            <option value={0}>No</option>
            <option value={1}>Si</option>
          </select>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-12 col-sm-6 my-1">
          <label htmlFor="jubilado">Jubilado *</label>
          <select
            className="form-select"
            name="datosPrevision.jubilado"
            aria-label="Default select example"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.datosPrevision.jubilado}
          >
            <option value="">Seleccione</option>
            <option value={0}>No</option>
            <option value={1}>Si</option>
          </select>
          {
            formik.touched.datosPrevision && formik.errors.datosPrevision && (
              <ErrorInput
                touched={formik.touched.datosPrevision.jubilado}
                error={formik.errors.datosPrevision.jubilado}
              />
            )
          }
        </div>
        <div className="col-12 col-sm-6 my-1">
          <label htmlFor="previsionSalud">Prevision de salud *</label>
          <select
            className="form-select"
            name="datosPrevision.previsionSalud"
            aria-label="Default select example"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.datosPrevision.previsionSalud}
          >
            <option value="">Seleccione</option>
            {listadoPrevision &&
              listadoPrevision.map((prevision) => (
                <option key={prevision.id} value={prevision.id}>
                  {prevision.nombre}
                </option>
              ))}
          </select>
          {
            formik.touched.datosPrevision && formik.errors.datosPrevision && (
              <ErrorInput
                touched={formik.touched.datosPrevision.previsionSalud}
                error={formik.errors.datosPrevision.previsionSalud}
              />
            )
          }
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-12 col-sm-6 my-1">
          <label htmlFor="previsionSocial">Prevision social *</label>
          <select
            className="form-select"
            name="datosPrevision.previsionSocial"
            aria-label="Default select example"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.datosPrevision.previsionSocial}
          >
            <option value="">Seleccione</option>
            {listadoAfp &&
              listadoAfp.map((afp) => (
                <option key={afp.id} value={afp.id}>
                  {afp.nombre}
                </option>
              ))}
          </select>
          {
            formik.touched.datosPrevision && formik.errors.datosPrevision && (
              <ErrorInput
                touched={formik.touched.datosPrevision.previsionSocial}
                error={formik.errors.datosPrevision.previsionSocial}
              />
            )
          }
        </div>

        {parseInt(formik.values.datosPrevision.previsionSalud) !== 1 &&
          formik.values.datosPrevision.previsionSalud !== "" && (
            <div className="col-6">
              <label htmlFor="planPactado">Plan pactado *</label>
              <div className="input-group">
                <span
                  className="input-group-text"
                  id="inputGroup-sizing-default"
                >
                  UF
                </span>
                <NumberFormat
                  className="form-control"
                  autoComplete="nope"
                  name="datosPrevision.planPactado"
                  value={formik.values.datosPrevision.planPactado}
                  style={{ paddingLeft: '10px' }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  decimalScale={4}
                  thousandSeparator="."
                  decimalSeparator=","
                  isAllowed={
                    (values, sourceInfo) => {
                      const { value } = values;
                      return value <= 99.9999;
                    }
                  }
                  allowNegative={false}

                />
              </div>
              {
                formik.touched.datosPrevision && formik.errors.datosPrevision && (
                  <ErrorInput
                    touched={formik.touched.datosPrevision.planPactado}
                    error={formik.errors.datosPrevision.planPactado}
                  />
                )
              }
            </div>
          )}
      </div>
    </>
  );
};

export default FormDatosPrevision;
