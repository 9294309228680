import React, { useContext } from 'react'
import { DataContext } from '../../contextData/contextData';
import { Icono } from './Iconos';
import '../../css/navBar.css';
import { modalEjecutar } from './sweetAlerts';
import { cerrarSession } from '../../utils/funciones';

const Navbar = () => {
  const { notificaciones } = useContext(DataContext);
  const { nombreMenu } = useContext(DataContext);

  return (
    <nav className="navbar d-flex justify-content-between align-items-center navbar-expand-sm navbar-light bg-light p-1 mb-4" style={{ borderBottom: "5px solid #1D70B6" }}>
      <div>
        <div className='d-flex justify-content-start'>
          <button
            className="btn btn-primary btnSidebar"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasExample"
            aria-controls="offcanvasExample"
            style={{ paddingRight: "10px" }}
          >
            <Icono iconName='arrowRight' />
          </button>
          <span className='h3 mb-0 ms-3 d-flex align-items-center'>{nombreMenu}</span>
        </div>
      </div>

      <ul id='itemBar' className='navbar-nav mb-lg-0 d-flex justify-content-end flex-row bgArcopime'>
        <li className="d-flex flex-column justify-content-center px-2" style={{ whiteSpace: 'nowrap', fontSize: '14px' }}>
          <label className="text-white"> {localStorage.getItem('usuarioNombre')} </label>
          <label className="text-white fw-bold"> {localStorage.getItem('perfil')} </label>
        </li>

        <li className="nav-item">
          <div className="dropdown text-end">
            <div className="p-2 d-block link-dark text-decoration-none dropdown-toggle btnCerrarSesion rounded-3" id="dropdownUser1" data-bs-toggle="dropdown">
              <img src={require('../../assets/untitled.png')} alt="mdo" width="40" height="40" className="rounded-circle" />
              {(notificaciones.length > 0) &&
                <span
                  className='badge bg-warning text-dark p-1 rounded-circle d-flex justify-content-center'
                  style={{
                    position: 'absolute',
                    fontSize: '11px',
                    top: '30px',
                    right: '62px',
                    height: '20px',
                    width: '20px',
                    fontWeight: '500',
                    cursor: 'pointer'
                  }}
                >
                  <Icono iconName='notif' />
                </span>
              }
            </div>
            <ul className="dropdown-menu dropdown-menu-end text-small" aria-labelledby="dropdownUser1" >
              {(notificaciones.length > 0) &&
                <li key={0} className="dropdown-item fw-bold d-flex justify-content-between align-items-center px-2" style={{ cursor: 'pointer' }}>
                  Notificaciones
                  <span
                    className="badge rounded-pill bg-warning text-dark d-flex justify-content-center align-items-center"
                    style={{ height: '20px', width: '20px', paddingBottom: '6px' }}
                  >{notificaciones.length}</span>
                </li>
              }
              <li key={1} style={{ cursor: 'pointer' }}>
                <a
                  className="dropdown-item px-2"
                  onClick={() => { modalEjecutar(cerrarSession, "Se cerrará la sesión, ¿Desea continuar?", null) }}
                >
                  Cerrar Sesión
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-right iconoSession" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z" />
                    <path fillRule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z" />
                  </svg>
                </a></li>
            </ul>
          </div>
        </li>
      </ul>
    </nav>
  )
}

export default Navbar