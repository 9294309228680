import { useState } from "react";

export const useDatosBancarios = () => {
  const [datosBancariosErr, setErrors] = useState({});

  const validateBancarios = obj => {
    let errObj = {};
    const { formaDePago, tipoCuenta, banco, numeroCuenta } = obj;

    if (!formaDePago) { errObj.formaDePago = "Requerido"; }
    if (formaDePago === "2" && !banco) { errObj.banco = "Requerido"; }
    if (formaDePago === "2" && !tipoCuenta) { errObj.tipoCuenta = "Requerido"; }
    if (formaDePago === "2" && !numeroCuenta) { errObj.numeroCuenta = "Requerido"; }

    setErrors(errObj);
    return errObj;
  }

  return { datosBancariosErr, validateBancarios }
}