import './noAutorizado.css';
import { useNavigate } from "react-router-dom";

export const NoAutorizado = () => {
    const navigate = useNavigate();

    return (
        <div className='unauthorizedContainer'>
            <div className="lock"></div>
            <div className="message">
                <h1>Acceso no autorizado</h1>
                <p>No tienes los permisos para acceder a esta página.</p>
            </div>
            <div>
                <button className='btn btn-sm btnBackToHome' onClick={() => { navigate(`/`) }}>Ir a la página principal</button>
            </div>
        </div>
    );
};