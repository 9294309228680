import { BASE_URL_PRODUCCION } from "../utils/Constants";
//constantes
const OBTENER_DATOS_AFP_EXITO = 'OBTENER_DATOS_AFP_EXITO';

const OBTENER_DATOS_PREVISION_EXITO = 'OBTENER_DATOS_PREVISION_EXITO';

const dataInicial = {
    listadoAfp: [],
    listadoPrevision: []
}

// reducer
export default function datosPrevisionalesReducer(state = dataInicial, action) {
    switch (action.type) {
        case OBTENER_DATOS_AFP_EXITO:
            return { ...state, listadoAfp: action.payload }
        case OBTENER_DATOS_PREVISION_EXITO:
            return { ...state, listadoPrevision: action.payload }
        default:
            return state;
    }
}



//acciones 
// URL para el consumo de api
const BASE_URL = BASE_URL_PRODUCCION;
const params = {
    headers:{
        Authorization: `Bearer ${localStorage.getItem('token')}`,
    }
}

export const obtenerDatosAfpAction = () => async (dispatch, getState, jubilado = 1) => {
    try {
        const url = `${BASE_URL}/listado/afp?jubilado=${jubilado}`
        const res = await fetch(url,params)
        const resJSON = await res.json()
        dispatch({
            type: OBTENER_DATOS_AFP_EXITO,
            payload: resJSON.data
        })
    } catch (error) {
        console.log("error :", error)
    }
}

export const obtenerDatosPrevisionAction = () => async (dispatch, getState) => {
    try {
        const url = `${BASE_URL}/listado/prevision`
        const res = await fetch(url,params)
        const resJSON = await res.json()
        dispatch({
            type: OBTENER_DATOS_PREVISION_EXITO,
            payload: resJSON.data
        })

    } catch (error) {
        console.log("error :", error)
    }
}

