import React from "react";
import { capitalizeFirstLetter, formatearYExtraerCrearColaborador } from '../../utils/funciones';
import Alert from 'react-bootstrap/Alert';
import Badge from 'react-bootstrap/Badge';




const DetalleLogScreen = ({logData, setViewData}) => {

    return (
        <div className="row mb-4">
            <div className="d-grid gap-2 d-md-flex justify-content-md-end my-2">
            </div>
            <div className="col-sm-12">
                <div className="card">
                    <div className="card-header bgArcopime d-md-flex justify-content-between align-items-center">
                        <label className='h6 my-0'>Detalle del alta del Colaborador - <strong>{capitalizeFirstLetter(logData.persona)}</strong></label>
                        <button className="btn btn-sm btn-light" type="button" onClick={() => {setViewData(0)}}>Volver</button>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-6">
                                <h4>Cuerpo de la solicitud</h4>

                                <span>{formatearYExtraerCrearColaborador(logData.payload)}</span>
                            </div>
                            <div className="col-md-6">
                                <div className="d-flex align-items-center">
                                    <h4>Respuesta de Temporis</h4> 
                                    <h6 style={{marginLeft: '10px'}}>
                                    <Badge className="ml-2" pill bg={logData.status === 1 ? 'success' : 'danger'} > {logData.status === 1 ? 'Éxistoso' : 'Fallido'}</Badge>
                                    </h6>
                                </div>
                                <Alert variant="info">
                                    Respuesta del servicio Temporis ha sido limpiada y formateada para entregar solo el mensaje de respuesta. Evitando mostrar etiquetado y mensajes confusos.
                                </Alert>
                                <span>{logData.resp_clean_temporis}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DetalleLogScreen;