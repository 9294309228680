import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import { useLocation, useNavigate } from 'react-router-dom';
import { obtenerDetalleVacanteAction } from '../redux/datosVacantesDucks';
import { momentFecha } from '../utils/funciones';
import Spinner from './ui/Spinner';

const DetalleVacanteScreen = () => {
  const { state } = useLocation();
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const detalleVacantes = useSelector((store) => store.datosVacantes.detalleVacante);
  const loading = useSelector((store) => store.datosVacantes.loading);

  useEffect(() => {
    const fetchData = () => { dispatch(obtenerDetalleVacanteAction(state.idSolicitud)) }
    fetchData()
  }, [dispatch])

  const volver = () => { return navigate(-1, { state }) }

  if (!loading && detalleVacantes === undefined) {
    return (<h3 className='text-center'>No existen registros aún.</h3>)
  }

  return (
    <div className="row">
      <div className="d-grid gap-2 d-md-flex justify-content-md-end my-2">
        <button className="btn btn-arcoprime-st" type="button" onClick={() => volver()}>Volver</button>
      </div>
      <div className="col-sm-12">
        <div className="card">
          <div className="card-header bgArcopime"><label className='h6 my-0'>Detalle de Solicitud</label></div>
          {
            loading ? (<Spinner />) : (<div className="card-body contenedorTabla">
              <div className="table-responsive">
                <table className="table table-bordered hover">
                  <thead className='headTablaSolicitud'>
                    <tr>
                      <th className='text-center'>Nombre</th>
                      <th className='text-center'>Unidad organizativa</th>
                      <th className='text-center'>Cargo</th>
                      <th className='text-center' style={{ width: '70px' }}>Fecha Proceso</th>
                      <th className='text-center'>Detalles</th>
                    </tr>
                  </thead>
                  <tbody className='cuerpoTablaDetSolicitud'>
                    {detalleVacantes &&
                      detalleVacantes.map((detalle) => (
                        <tr nowrap key={detalle.id}>
                          <td className='tdTable'>{detalle.nombre_apellido}</td>
                          <td className='tdTable'>{detalle.unidad_organizativa}</td>
                          <td className='tdTable'>{detalle.cargo_actual}</td>
                          <td className='text-center tdTable' style={{ width: '70px' }}>
                            {momentFecha(detalle.fecha_actualiza_proceso)}
                          </td>
                          <td className='tdTable' style={{ minWidth: '300px' }}>
                            <p className='m-0'><b>Asignante:</b> {detalle.responsable}</p>
                            <p className='m-0'><b>Estado asignado:</b> {detalle.estado_actual}</p>
                            <p className='m-0'><b>Observación:</b> {detalle.observaciones ? detalle.observaciones : 'No hay observaciones'}</p>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
            </div>
            )}
        </div>
      </div>
    </div>
  )
}

export default DetalleVacanteScreen