import { ModalVerNotificaciones } from "./components/ui/sweetAlerts";
import { ContextApi } from "./contextData/contextData";
import { SideBar } from "./components/ui/SideBar";
// import { Footer } from "./components/ui/Footer";
import Navbar from "./components/ui/Navbar";
import generateStore from "./redux/store";
import AppRoute from "./routes/AppRoute";
import { Provider } from "react-redux";

function App() {

  const store = generateStore();

  return (
    <>
      <div className="container-fluid p-0">
        <main>
          <Provider store={store}>
            <ContextApi>
              <ModalVerNotificaciones visible={false} />
              <Navbar />
              <SideBar />

              <AppRoute />
            </ContextApi>
          </Provider>
        </main>
        {/* <Footer /> */}
      </div>
    </>
  );
}

export default App;
