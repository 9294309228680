import React from 'react'

const Spinner = () => {
  return (
    <>
      <div className="text-center">
        <div className="spinner-border text-primary m-3" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    </>
  )
}

export default Spinner

export const SpinnerModal = ({ txt }) => {
  return (
    <>
      <div className="border rounded-3 py-1 px-2 mb-5 mx-3 d-flex flex-row justify-content-between align-items-center">
        <div>
          <label className='fw-bold'>Guardando: </label>
          <label className='ms-1'>{txt}...</label>
        </div>
        <div style={{ height: '17px', width: '17px', paddingTop: '4px' }} className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    </>
  )
}