// React
import DataTable, { defaultThemes } from 'react-data-table-component';
import { useEffect, useState } from 'react';
import jwtDecode from 'jwt-decode';

// Componentes y utils generales
import { mensajeSimple, detalleContratosEnviados, modalAprobarRechazarContrato, modalCargando, ModalEnviandoContratos, detalleContratos } from '../../../components/ui/sweetAlerts';
import { paginationComponentOptions, objStyleCol } from '../../../utils/Constants';
import Spinner from '../../../components/ui/Spinner';
import { Icono } from '../../../components/ui/Iconos';

import { devolverPlural, } from '../../../utils/funciones';
// Apis
import { get, getUno, post } from '../../../api/apis';;

const resolucionContrato = async (obj) => {
  modalCargando(`${obj.respuesta === '1' ? "Aprobando " : "Rechazando "}contrato${devolverPlural(obj)}...`);
  const data = { ...obj, idPersona: jwtDecode(localStorage.getItem('token')).id_usuario_sap }

  const resp = await post('revisarcontrato', {}, data);

  if (resp.data && resp.data.status === 'success') {
    mensajeSimple(
      resp.data.status,
      `Contrato${devolverPlural(obj)} ${data.respuesta === '1' ? 'aprobado' : 'rechazado'}${devolverPlural(obj)} con éxito.`,
      4000
    );


  }
  else {
    mensajeSimple('error', 'Ha ocurrido un problema!');
  }
}

const Contrato = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [contratosArray, setContratosArray] = useState([]);
  const [totalRows, setTotalRows] = useState(null);
  const [perPage, setPerPage] = useState(10);
  const [pageTable, setPageTable] = useState(1);
  const [count, setCount] = useState(0);
  const [contratoName, setContratoNAme] = useState('');
  const [infoContrato, setInfoContrato] = useState('');
  const [visibleModal, setVisibleModal] = useState(false)
  const [lenght, setLenght] = useState(0)

  useEffect(() => { llamarPagina(); }, []);

  function capitalizeWords(text) {
    return text
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  const columnas = {
    admin: [
      {
        name: 'ID Lote',
        sortable: false,
        selector: row => row.IdLote,
        width: '95px',
        style: objStyleCol
      },
      {
        name: 'N° Personal',
        sortable: false,
        selector: row => row.NPersonal,
        width: '90px',
        style: objStyleCol
      },
      {
        name: 'Nombre colaborador',
        sortable: false,
        selector: row => capitalizeWords(row.Nombre),
        wrap: true,
        width: '330px',
      },
      {
        name: 'Rut',
        sortable: false,
        selector: row => row.Rut,
        width: '98px',
        style: objStyleCol
      },
      {
        name: 'División',
        sortable: false,
        selector: row => row.Division,
        width: '80px',
        style: objStyleCol
      },
      {
        name: 'Unidad organizativa',
        sortable: false,
        selector: row => row.TextoUnidadOrganizativa,
        wrap: true,
        width: '360px',
      },
      {
        name: 'Subdivisión',
        sortable: false,
        selector: row => row.Subdivision,
        wrap: true,
        width: '100px',
        style: objStyleCol
      },
      {
        name: 'Cargo',
        sortable: false,
        selector: row => row.Cargo,
        wrap: true,
        width: '250px',
      },
      {
        name: 'Fecha ingreso',
        sortable: false,
        selector: row => row.FechaIngreso.replace(/\./g, '-'),
        wrap: false,
        width: '90px',
        style: objStyleCol
      },
      {
        name: 'Fecha Fin Contrato',
        sortable: false,
        selector: row => row.FechaFinContrato.replace(/\./g, '-'),
        wrap: false,
        width: '90px',
        style: objStyleCol
      },
      {
        name: 'Horario',
        sortable: false,
        selector: row => row.Phtd,
        wrap: true,
        width: '90px',
      },
      {
        name: 'Analista',
        sortable: false,
        selector: row => row.UsuarioSapEmisor,
        wrap: true,
        width: '140px',
      },
      {
        name: 'Fecha de Emisión',
        sortable: false,
        selector: row => row.FechaEmisionContrato.replace(/\./g, '-'),
        wrap: false,
        width: '90px',
        style: objStyleCol
      },
      {
        name: 'Plantilla Utilizada',
        sortable: false,
        selector: row => row.NombrePlantilla,
        wrap: true,
        width: '250px',
      },
      {
        name: 'Documento',
        cell: (row) => <button onClick={() => mostrarPDF(row.id)} className="btn px-1 btn-danger border-0 btn-sm"><Icono iconName='pdf' /></button>,
        style: {
          display: 'flex', justifyContent: 'center'
        }
      },
    ],
    rrhh: [
      {
        name: 'N° Personal',
        sortable: false,
        selector: row => row.NPersonal,
        width: '85px',
        style: objStyleCol
      },
      {
        name: 'Nombre colaborador',
        sortable: false,
        selector: row => capitalizeWords(row.Nombre),
        wrap: true,
        minWidth: '300px',
      },
      {
        name: 'Rut',
        sortable: false,
        selector: row => row.Rut,
        width: '100px',
        style: objStyleCol
      },
      {
        name: 'Unidad organizativa',
        sortable: false,
        wrap: true,
        selector: row => row.TextoUnidadOrganizativa,
        minWidth: '140px',
      },
      {
        name: 'Cargo',
        sortable: false,
        selector: row => row.Cargo,
        wrap: true,
        width: '150px',
      },
      // inicio nuevo contenido para el administrador
      {
        name: 'Plantilla',
        sortable: false,
        selector: row => row.NombrePlantilla,
        wrap: true,
        width: '250px',
      },
      // fin nuevo contenido para el adminstrador
      {
        name: 'Fecha Ingreso',
        selector: row => row.FechaIngreso.replace(/\./g, '-'),
        wrap: true,
        width: '90px',
        style: objStyleCol
      },
      {
        name: 'Fecha Fin Contrato',
        selector: row => row.FechaFinContrato.replace(/\./g, '-'),
        wrap: false,
        width: '90px',
        style: objStyleCol
      },
      {
        name: 'Documento',
        cell: (row) => <button onClick={() => mostrarPDF(row.id)} className="btn px-1 btn-danger border-0 btn-sm"><Icono iconName='pdf' /></button>,
        style: {
          display: 'flex', justifyContent: 'center'
        }
      },
    ]
  }

  const seleccionarContrato = (obj) => {
    let arr = [];
    const checks = document.querySelectorAll('.rdt_TableBody input[type="checkbox"]');
    checks.forEach((el, idx) => {
      if (el.checked) { arr.push(data[idx]); }
    });
    setContratosArray(arr);
  }

  const borrarChecks = () => {
    if (!document.getElementsByName('select-all-rows')[0]?.checked) {
      document.getElementsByName('select-all-rows')[0]?.click();
    }
    document.getElementsByName('select-all-rows')[0]?.click();
    setContratosArray([])
  }

  const llamarPagina = async (page = 1, per_page = 10) => {

    setLoading(true);
    borrarChecks();
    const resp = await get(`monitor?page=${page}&per_page=${per_page}`, null, null);
    setLoading(false);

    if (resp === 500) { mensajeSimple('error', 'La sessión ha caducado.', 3000); }

    if (resp.data.status === 'info') {
      setData([]);
      mensajeSimple(resp.data.status, resp.data.message, 2000);
    }
    else if (resp.data.success) {
      const { data, total } = resp.data.data;
      setData(data);
      setTotalRows(total);
    }

  }

  const getPerfil = (idPerfil, columnas) => {
    if (idPerfil === '3') { return columnas.rrhh; }
    if (idPerfil === '4') { return columnas.admin; }
    else { return columnas.admin; }
  }

  const mostrarPDF = async (id) => {
    /* Este codigo es para caso de que el hecho del binario sea demasiado pesado,  traer el binario desde aca directamente
    Para que funcione debes pasar como argumento a esta funcion el ID del contrato*/

    modalCargando(`Cargando documento...`)
    let strBase64 = '';
    const resp = await getUno('revisarcontrato', { idContrato: id });

    if (resp.data && resp.status === 200) {
      strBase64 = resp.data.Binario;
      mensajeSimple('success', 'Documento cargado con éxito', 2000);
    }


    window.open(strBase64, '_blank');
  }

  const handlePageChange = page => {
    setPageTable(page);
    const el = document.getElementsByName('select-all-rows')[0];

    if (el.checked) {
      const ev = new Event('click', { bubbles: true });
      el.dispatchEvent(ev);
    }
    llamarPagina(page, perPage);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setPerPage(newPerPage);
    llamarPagina(page, newPerPage);
  };

  const resolucionContrato = async (obj) => {
    setVisibleModal(true)
    //setLenght(obj.idContrato.length);
    //aca
    modalCargando(`Enviando documentos...`);

    const arr = obj.idContrato.map(i => i.id);
    const resp = await post('revisarcontrato', {}, { idContrato: arr, respuesta: obj.respuesta, motivo: obj.motivo, idPersona: jwtDecode(localStorage.getItem('token')).id_usuario_sap });
    // llamarPagina();

    setVisibleModal(false)
    if (resp.data.status === 'success') {
      detalleContratosEnviados(resp.data.status, resp.data.message, resp.data.data[0].data, llamarPagina, resp.data.errors);
    } else {
      detalleContratos(resp.data.status, resp.data.message, resp.data.data ? resp.data.data : '', llamarPagina);
    }
    setContratoNAme(() => setContratoNAme(''))
    setInfoContrato(() => setInfoContrato(''))
  }

  const customStyles = {
    tableBody: {
      style: {
        borderLeftStyle: 'solid',
        borderLeftWidth: '1px',
        borderLeftColor: defaultThemes.default.divider.default,
      },
    },
    header: {
      style: {
        minHeight: '40px',
      },
    },
    headRow: {
      style: {
        borderTopStyle: 'solid',
        borderTopWidth: '1px',
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        '&:not(:last-of-type)': {
          borderRightStyle: 'solid',
          borderRightWidth: '1px',
          borderRightColor: defaultThemes.default.divider.default,
        },
        marginLeft: '2px',
        marginRight: '2px',
      },
    },
    cells: {
      style: {
        '&:not(:last-of-type)': {
          borderRightStyle: 'solid',
          borderRightWidth: '1px',
          borderRightColor: defaultThemes.default.divider.default,
        },
        marginLeft: '2px',
        marginRight: '2px',
      },
    },
    responsiveWrapper: {
      style: {
        border: `1px solid ${defaultThemes.default.divider.default}`,
      }
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-sm-12 ">
          <div className="card cardPestania">

            <>
              <ModalEnviandoContratos actual={count} max={lenght} contratoName={contratoName} infoContrato={infoContrato} visibleModal={visibleModal} />
              {
                loading
                  ? (<Spinner />)
                  : (
                    <div className="card-body contenedorTabla">
                      {data.length > 0
                        ?
                        <DataTable
                          title={false}
                          responsive={true}
                          columns={getPerfil(localStorage.getItem('id_perfil'), columnas)}
                          data={data}
                          progressPending={loading}
                          pagination
                          paginationDefaultPage={pageTable}
                          paginationPerPage={perPage}
                          paginationTotalRows={totalRows}
                          onChangeRowsPerPage={handlePerRowsChange}
                          onChangePage={handlePageChange}
                          paginationServer={true}
                          paginationComponentOptions={paginationComponentOptions}
                          selectableRows
                          customStyles={customStyles}
                          onSelectedRowsChange={seleccionarContrato}
                          dense
                          fixedHeader
                          fixedHeaderScrollHeight="440px"
                        />
                        :
                        <p className='h5 text-center my-3 titleMensajeSimple'>No se encontraron registros</p>
                      }
                    </div>
                  )
              }
            </>
          </div>
        </div>
      </div>
      {contratosArray.length > 0 &&
        <div className="row mt-3">
          <div className="col-sm-12">
            <button onClick={() => modalAprobarRechazarContrato(contratosArray, resolucionContrato)} className="ms-0 pe-2 btnCopec">
              Revisar contrato{contratosArray.length > 1 ? 's' : ''}
            </button>
          </div>
        </div>
      }
    </>
  )
}

export default Contrato;