import React from "react";
import { EditarContratacionScreen } from "../components/EditarContratacionScreen";
import FormContratacionScreen from "../components/FormContratacionScreen";
import ListadoVacantesScreen from "../components/ListadoVacantesScreen";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import DetalleVacanteScreen from "../components/DetalleVacanteScreen";
import ConsultarRutScreen from "../components/ConsultarRutScreen";
import VerVacateScreen from "../components/VerVacateScreen";
import IngresoIntranet from "../components/IngresoIntranet";
import ListadoVacantesAprobadasScreen from "../components/ListadoVacantesAprobadasScreen ";
import { PestaniasReingreso } from "../components/ListadoSolicitudReingreso";

// // Componentes viejos
import { PorEnviarSAP } from "../components/PorEnviarSAP"; // descomantar para usar el componete viejo
import { ResumenSolicitudes } from "../components/ResumenSolicitudes"; // descomantar para usar el componente viejo
// import { PestaniasFirmaContrato } from "../components/FirmaContrato";  // descomantar para usar el componente viejo

// // Componentes Nuevos
import PestaniasFirmaContrato from '../pages/firmaContrato/FirmaContrato';

// // Componentes Nuevos
// // Todas las nuevas vista se estan trabajando en la carpeta 'pages'
// import PorEnviarSAP from "../pages/porEnviarSAP/PorEnviarSAP";
// import ResumenSolicitudes from "../pages/resumenSolicitudes/ResumenSolicitudes";
// import PestaniasFirmaContrato from '../pages/firmaContrato/FirmaContrato';
import Monitor from '../pages/monitor/Index';
import { NoAutorizado } from '../pages/errores/noAutorizado/noAutorizado';
import { PageNotFound } from "../pages/errores/PageNotFound/404page";
import {AccesoDenegado} from '../pages/errores/accesoDenegado/accesoDenegado';

import FirmaContrato from "../pages/firmaContrato/FirmaContrato";
import DetalleLogScreen from "../pages/temporis/VerDetalleLog";
import LogTemporis from "../pages/temporis/LogTemporis";

const Acceso = ({ el, acc = [] }) => acc.includes(localStorage.getItem('id_perfil')) ? el : <NoAutorizado />;
const AccesoFirma = ({ el, acc = [] }) => acc.includes(localStorage.getItem('puedeverfirma')) ? el : <NoAutorizado />;

const ReturnIndex = () => {
  return {
    '6': <Monitor />,
    '7': <FirmaContrato />,
  }[localStorage.getItem('id_perfil')] || <ListadoVacantesScreen />;
}

const puedeVer = () => {
  const puede = localStorage.getItem('puedeverfirma');
  return puede;
}

const AppRoute = () => {
  return (
    <div className="containerfluid px-4 p-0">
      <Routes>
        <Route path="/" element={<ReturnIndex />} />
        <Route path="/listSolicitudAprobadas" element={<ListadoVacantesAprobadasScreen />} />
        <Route path="/listSolicitudReingreso" element={<PestaniasReingreso />} />
        <Route path="/consulta-rut" element={<Acceso el={<ConsultarRutScreen />} acc={['4']} />} />
        <Route path="/formulario-contratacion/:idPersona" element={<Acceso el={<FormContratacionScreen />} acc={['4']} />} />
        <Route path="/formulario-contratacion" element={<Acceso el={<FormContratacionScreen />} acc={['4']} />} />
        <Route path="detalle-vacante/:idSolicitud" element={<DetalleVacanteScreen />} />
        <Route path="/firma-contrato" element={<AccesoFirma el={<PestaniasFirmaContrato />} acc={['1']} />} />
        <Route path="/envio-sap" element={<Acceso el={<PorEnviarSAP />} acc={['3']} />} />
        <Route path="vacante/:idSolicitud" element={<VerVacateScreen />} />
        <Route path="editar-vacante/:idSolicitud" element={<EditarContratacionScreen />} />
        <Route path="ingreso-intranet/:token" element={<IngresoIntranet />} />   
        <Route path="log-temporis/" element={<LogTemporis />} />
        <Route path="detalle-temporis/:id" element={<DetalleLogScreen />} />
        {/* <Route path="page-not-found" element={<NotFoundPage />} /> */}
        {/* <Route path="unauthorized" element={<UnauthorizedPage />} /> */}
        <Route path="/resumen-solicitudes" element={<ResumenSolicitudes />} />
        <Route path="/monitor" element={<Acceso el={<Monitor />} acc={['6']} />} />
        <Route path='/acceso-denegado' element={<AccesoDenegado />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </div>
  );
};

export default AppRoute;
