export const LabelTamanioMax = () => {
  return (
    <div className="badge p-0 ms-2 d-inline-flex float-right"
    style={{width: '134px', alignItems: 'center'}}>
      <div
        className="bg-warning text-dark p-1 w-50"
        style={{borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px'}}
      >
        Máx 10mb
      </div>
      <div
        className="bg-danger text-white p-1 w-50"
        style={{borderTopRightRadius: '5px', borderBottomRightRadius: '5px'}}
      >
        Requerido
      </div>
    </div>
  )
}